import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { useState } from 'react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import { Pagination } from 'hds-react';
export const BasicPagination = () => {
  const [pageIndex, setPageIndex] = useState(0);
  return <Pagination onChange={(event, index) => {
    event.preventDefault();
    setPageIndex(index);
  }} paginationAriaLabel="Pagination 1" pageCount={9} pageIndex={pageIndex} pageHref={() => '#'} language="en" siblingCount={4} mdxType="Pagination" />;
};
export const TruncationPagination = () => {
  const [pageIndexPagination2, setPageIndexPagination2] = useState(3);
  const [pageIndexPagination3, setPageIndexPagination3] = useState(16);
  const [pageIndexPagination4, setPageIndexPagination4] = useState(64);
  return <div>
      <Pagination language="en" onChange={(event, index) => {
      event.preventDefault();
      setPageIndexPagination2(index);
    }} pageCount={68} pageHref={() => '#'} pageIndex={pageIndexPagination2} paginationAriaLabel="Pagination 2" siblingCount={2} mdxType="Pagination" />
      <Pagination language="en" onChange={(event, index) => {
      event.preventDefault();
      setPageIndexPagination3(index);
    }} pageCount={68} pageHref={() => '#'} pageIndex={pageIndexPagination3} paginationAriaLabel="Pagination 3" siblingCount={2} mdxType="Pagination" />
      <Pagination language="en" onChange={(event, index) => {
      event.preventDefault();
      setPageIndexPagination4(index);
    }} pageCount={68} pageHref={() => '#'} pageIndex={pageIndexPagination4} paginationAriaLabel="Pagination 4" siblingCount={2} mdxType="Pagination" />
    </div>;
};
export const WithoutPrevAndNextButton = () => {
  const [pageIndex, setPageIndex] = useState(7);
  return <Pagination hideNextButton hidePrevButton onChange={(event, index) => {
    event.preventDefault();
    setPageIndex(index);
  }} paginationAriaLabel="Pagination 5" pageCount={68} pageIndex={pageIndex} pageHref={() => '#'} language="en" mdxType="Pagination" />;
};
export const _frontmatter = {
  "slug": "/components/pagination",
  "title": "Pagination",
  "navTitle": "Pagination"
};
const layoutProps = {
  BasicPagination,
  TruncationPagination,
  WithoutPrevAndNextButton,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <BasicPagination mdxType="BasicPagination" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Pagination is used when content is divided into multiple pages and the user needs to be able to move between those pages.`}</li>
      <li parentName="ul">{`You must at least have three (3) pages in the pagination.`}</li>
      <li parentName="ul">{`Keep the amount of shown pages between screen size changes consistent. `}
        <ul parentName="li">
          <li parentName="ul">{`Aim to show at least 5 pages and 9 pages at maximum in one row.`}</li>
          <li parentName="ul">{`You can change the amount of shown pages when the view switches from desktop to mobile.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`A good rule of thumb for a maximum amount of items per page is 36. However, this heavily depends on the type of items displayed.`}
        <ul parentName="li">
          <li parentName="ul">{`With smaller items, the number can be larger, e.g. 72.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The pagination must always include `}<em parentName="li">{`Previous`}</em>{` and `}<em parentName="li">{`Next`}</em>{` page buttons. Do not hide or omit these buttons.`}</li>
      <li parentName="ul">{`The HDS pagination component supports truncation, which means hiding part of the pages with the "..." (ellipsis) element.`}
        <ul parentName="li">
          <li parentName="ul">{`Truncation is recommended if your page numbers start to spread into multiple rows or if you would have more than 9 page numbers in one row.`}</li>
          <li parentName="ul">{`Truncation can happen either at the start, at the end, or at both ends. See the `}<a parentName="li" {...{
              "href": "#with-truncation"
            }}>{`truncation example`}</a>{` below for more information.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The pagination component must be aligned to the center of its container.`}
        <ul parentName="li">
          <li parentName="ul">{`On mobile, the previous and next buttons are aligned to their respective edges of the container.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "should-i-use-pages-or-dynamically-load-more-content",
      "style": {
        "position": "relative"
      }
    }}>{`Should I use pages or dynamically load more content?`}<a parentName="h3" {...{
        "href": "#should-i-use-pages-or-dynamically-load-more-content",
        "aria-label": "should i use pages or dynamically load more content permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use pages when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`the amount of items in one page/view exceeds 36 items`}</li>
          <li parentName="ul">{`divided into pages, you will have at least 3 pages`}</li>
          <li parentName="ul">{`you are not sure how much content there will be (i.e. there may be 10 pages or 100 pages)`}</li>
          <li parentName="ul">{`the user may need to be able to move to the end of the item list`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Use dynamic loading when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`the amount of items in one page/view is less than 36`}</li>
          <li parentName="ul">{`divided into pages, you will have less than 3 pages`}</li>
          <li parentName="ul">{`you have an estimation of how many items there will be`}</li>
          <li parentName="ul">{`the user does not need to be able to move to the end of the item list`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "basic",
      "style": {
        "position": "relative"
      }
    }}>{`Basic`}<a parentName="h4" {...{
        "href": "#basic",
        "aria-label": "basic permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`By default, the HDS pagination shows a list of pages, and one page is marked as the active page. The previous and next buttons are always shown and they drop under the page number row on smaller screens.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <BasicPagination mdxType="BasicPagination" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-truncation",
      "style": {
        "position": "relative"
      }
    }}>{`With truncation`}<a parentName="h4" {...{
        "href": "#with-truncation",
        "aria-label": "with truncation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`In the truncated pagination variant some of the pages are hidden with the "..." (ellipsis) element. In the React version,
you can use the property `}<inlineCode parentName="p">{`siblingCount`}</inlineCode>{` that tells the number of always visible pages before and after the current page.
Truncation can happen either at the start, in the end, or at both ends - depending on the page the user is currently on.`}</p>
    <p>{`Note that you must keep the amount of shown pages and ellipsis consistent. For example, in the examples below,
there are always at least 2 pages before and after the current page (`}<inlineCode parentName="p">{`siblingCount`}</inlineCode>{` is 2). And the total number of
pagination elements is 9, regardless of the page the user is currently on. This keeps the pagination element width
consistent, which leads to a better user experience.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <TruncationPagination mdxType="TruncationPagination" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "without-previous-and-next-buttons",
      "style": {
        "position": "relative"
      }
    }}>{`Without previous and next buttons`}<a parentName="h4" {...{
        "href": "#without-previous-and-next-buttons",
        "aria-label": "without previous and next buttons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`While not recommended, it's possible to use the pagination component without the previous and next buttons.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <WithoutPrevAndNextButton mdxType="WithoutPrevAndNextButton" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      